import React, { Component } from 'react'
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarTe from "../../components/layout/sidemenu/sidemenu_te"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import Auth from '../../auth/auth'
import SignInOutButton from "../../components/auth/sign-in-out-button"

let auth
if (typeof window !== `undefined`) {
  auth = new Auth()
}

const ProgramMaterialsPage = class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: undefined
    }
  }

  componentDidMount() {
    const user = auth.getUser()
    if (user) {
      this.setState({user_email: user.email, authenticated: auth.isAuthenticated()})
    } else {
      this.setState({ authenticated: auth.isAuthenticated() })
    }
  }

  signin = (e) => {
    e.preventDefault()
    auth.signin()

    this.setState({authenticated: auth.isAuthenticated()})
  }

  signout = (e) => {
    e.preventDefault()
    auth.signout()

    this.setState({authenticated: auth.isAuthenticated()})
  }

  render() {
    return (
      <Layout location={"/teacher-resources/"} >
        <SEO title="Carbon Connections - Program Materials" />
        <Container fluid className="mb-5">
          <Row>
            <Col md="auto">
              <SideBarTe location={this.props.location.pathname} />
            </Col>
            <Col>
              <CCBreadcrumb
                pathname={this.props.location.pathname}
                title={'Program Materials'}
                replace={this.props.replace}
              />
              <Alert
                className="w-100"
                variant="warning"
              >
                BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
              </Alert>
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header className="cardHeader">
                          <h1>
                            <span className="headerFontBlue">Program Materials</span>
                          </h1>
                        </Card.Header>
                        {auth && !auth.isAuthenticated() &&
                          <Card.Body className="cardBodyBackground">
                            <div className="d-flex justify-content-center">
                              <SignInOutButton
                                signin={this.signin}
                                signout={this.signout}
                                authenticated={this.state.authenticated}
                              />
                            </div>
                          </Card.Body>
                        }
                        {auth && auth.isAuthenticated() &&
                          <Card.Body className="cardBodyBackground">
                            <Row className="mb-4">
                              <Col xs={12} md={6}>
                                <Card className="h-100">
                                  <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                    <h2>Materials List</h2>
                                  </Card.Header>
                                  <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                    <ul className="list-unstyled">
                                      <li className="mb-3">
                                        <a target="_blank" rel="noopener noreferrer" href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/program_materials/carbonconnections_materials_list.pdf">Carbon Connections Materials List&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                                      </li>
                                    </ul>
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col xs={12} md={6}>
                                <Card className="h-100">
                                  <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                    <h2>Handouts</h2>
                                  </Card.Header>
                                  <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                    <ul className="list-unstyled">
                                      <li className="mb-3">
                                        <a target="_blank" rel="noopener noreferrer" href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/program_materials/carbonconnections_handouts_units_1to3.pdf">Carbon Connections Handouts&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                                      </li>
                                    </ul>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>

                            <Row className="mb-4">
                              <Col xs={12} md={6}>
                                <Card className="h-100">
                                  <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                    <h2>Advance Prep Notes</h2>
                                  </Card.Header>
                                  <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                    <ul className="list-unstyled">
                                      <li className="mb-3">
                                        <a target="_blank" rel="noopener noreferrer" href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/program_materials/carbconn_advanced_prep_general_and_units_1to3.pdf">Carbon Connections Advanced Prep&mdash;General and Units 1-3&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                                      </li>
                                    </ul>
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col xs={12} md={6}>
                                <Card className="h-100">
                                  <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                    <h2>Unit Organizers</h2>
                                  </Card.Header>
                                  <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                    <p className="lead">Unit 1: Carbon &amp; Climate in the Past</p>
                                    <ul className="list-unstyled">
                                      <li className="mb-3">
                                        <a target="_blank" rel="noopener noreferrer" href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/program_materials/carbon_connections_unit_1_organizer.pdf">Carbon Connections Unit 1 Organizer&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                                      </li>
                                    </ul>
                                    <p className="lead">Unit 2: Carbon Now</p>
                                    <ul className="list-unstyled">
                                      <li className="mb-3">
                                        <a target="_blank" rel="noopener noreferrer" href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/program_materials/carbon_connections_unit_2_organizer.pdf">Carbon Connections Unit 2 Organizer&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                                      </li>
                                    </ul>
                                    <p className="lead">Unit 3: Carbon in the Future and You</p>
                                    <ul className="list-unstyled">
                                      <li className="mb-3">
                                        <a target="_blank" rel="noopener noreferrer" href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/program_materials/carbon_connections_unit_3_organizer.pdf">Carbon Connections Unit 3 Organizer&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                                      </li>
                                    </ul>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>

                            <Row className="mb-4">
                              <Col xs={12} md={6}>
                                <Card className="h-100">
                                  <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                    <h2>Lesson Outcomes and Indicators</h2>
                                  </Card.Header>
                                  <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                    <p className="lead">Unit 1: Carbon &amp; Climate in the Past</p>
                                    <ul className="list-unstyled">
                                      <li className="mb-3">
                                        <a target="_blank" rel="noopener noreferrer" href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/program_materials/carbonconnections_unit_1_outcomes_and_indicators.pdf">Carbon Connections Unit 1 Outcomes &amp; Indicators&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                                      </li>
                                    </ul>
                                    <p className="lead"> Unit 2: Carbon Now</p>
                                    <ul className="list-unstyled">
                                      <li className="mb-3">
                                        <a target="_blank" rel="noopener noreferrer" href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/program_materials/carbonconnections_unit_2_outcomes_and_indicators.pdf">Carbon Connections Unit 2 Outcomes &amp; Indicators&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                                      </li>
                                    </ul>
                                    <p className="lead">Unit 3: Carbon in the Future and You</p>
                                    <ul className="list-unstyled">
                                      <li className="mb-3">
                                        <a target="_blank" rel="noopener noreferrer" href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/program_materials/carbonconnections_unit_3_outcomes_and_indicators.pdf">Carbon Connections Unit 3 Outcomes &amp; Indicators&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                                      </li>
                                    </ul>
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col xs={12} md={6}>
                                <Card className="h-100">
                                  <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                    <h2>Scientific References</h2>
                                  </Card.Header>
                                  <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                    <ul className="list-unstyled">
                                      <li className="mb-3">
                                        <a target="_blank" rel="noopener noreferrer" href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/program_materials/carbconn_scientific_references.pdf">Scientific References&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup></a>
                                      </li>
                                    </ul>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                          </Card.Body>
                        }
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default ProgramMaterialsPage
